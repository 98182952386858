import React, { useEffect, useState, useContext, useRef } from "react"
import DynamicForm from "../../../../components/FormBuilder/DynamicForm"
import FormTemplateId from "../../../../constants/FormTemplateList"
import ChildService from "./ChildService"
import querystringify from "querystringify"
import FormContainer from '../../../../components/FormBuilder/FormContainer'
import GeoLocation from "../../../../components/Common/GeoLocation"
import GenericModal from "../../../../components/Modals/GenericModal"
import NutritionCareNotification from '../../../NutritionCares/NutritionCareNotification'
import useModal from "../../../../hooks/useModal"
import { JourneyContext } from '../../List/JourneyContext'
import CheckAccess from '../../../Roles/CheckAccess'
import { useCurrentUserHook } from "../../../Authentication/useUserHook"
import moment from '../../../../../node_modules/moment/min/moment.min.js'

function ChildForm(props) {
  let { current: scope } = useRef({ formFn: {}});
  const {currentUser} = useCurrentUserHook()
  let {journeyState:{activeJourney}, journeyDispatch} = useContext(JourneyContext);

  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  const { isOpen:isNutritionCareOpen, toggleModal:toggleNutritionCareModal } = useModal();

  let params = props.match.params;
  let forigenObject = props.location.state;
  let queryParam = querystringify.parse(props.location.search);

  const get = () => {
    let req = {
      'journey_profile_id': params.journey_profile_id,
      'child_type': 'child' 
    }
    
    ChildService.get(params.id, req).then((res) => {
      if (res.status === 200) {
        scope.child = {...res.data.child, ...scope.child.geo_data};
        scope.data = scope.child.data || {};
        if(scope.child.id){
          scope.formMode = "edit-form-submissions";
          scope.formFn.editPermission = CheckAccess.hasMcjActivityEditAccess(currentUser, scope.child, 'child_birth');
        }
        setFormRendered(true);
      }
    }).catch((res) => 
      scope.child = null
    )
  }

  useEffect(() => {
    scope.child = {};
    scope.data = {};
    scope.formMode = "create-form-submissions";
    scope.formFn = {
      "form_type": "infant.child",
      "journey_profile_id": params.journey_profile_id
    }
    
    if (params.id) {
      get();
      scope.formMode = "edit-form-submissions";
    } else if (!isFormRendered) {
      setFormRendered(true);
    }
  }, [])

  /*Medical Facility Auto Complete*/
  scope.formFn.phc_path = 'name';

  scope.formFn.get_phc = (search, onSuccess, onFailure) =>{
    const org = currentUser.organizations.find((o)=> o.id === currentUser.current_organization_id);
    onSuccess(org.medical_facilities);
    /*let req = {
      organization_id: currentUser.current_organization_id,
      medical_facility_id: currentUser.current_medical_facility_id
    }
    SubFacilityService.getMemberSubFacilities(req).then(({status, data})=>{
      if(status === 200){
        //setSubFacilityList(data.sub_facilities)
      }
    })*/
  }

  scope.formFn.set_phc = (phc) =>{
    scope.data.phc = phc.name;
    scope.data.medical_facility_id = phc.id;
  }

  const resetError = (error) =>{
    error.invalid = false;
    error.err_msg = null;
  }

  /*formFn.on_select_weight = (weight, error) =>{
    try{
      error.err_msg = null
      if(weight.label != null && parseFloat(weight.label) > 5){      
        //error.err_msg = `Please check the value you have submitted to make sure it is correct.`
      }
    }catch(e){
      console.error(e.message)
    }
  }*/

  scope.formFn.set_min_dob_baby = (attributes) => {
    
  }

  const create = (newChild) => {
    if(forigenObject)newChild.data = Object.assign(forigenObject, scope.child.data); 
    newChild.organization_id = currentUser.current_organization_id;
    newChild.created_by = currentUser.id;
    newChild.updated_by = currentUser.id;
    newChild.journey_profile_id = params.journey_profile_id;
    newChild.child_form_id = (queryParam.form_id || FormTemplateId.HealthChildFormBasic);
    newChild.child_type = "child";
    newChild.member_role_id = currentUser.current_member?.role_record_id;
    newChild.associated_journey_id = queryParam.associated_journey_id; //check where it is coming from
    ChildService.create(newChild).then((res) => {
      if (res.status === 201) {
        scope.child = res.data.child;
        scope.formFn.activity = res.data.activity;
        //formFn.associated_journeys = res.data.associated_journeys
        if(res.data.activity?.show_nutrition_care){
          scope.formFn.setLoading(false);
          toggleNutritionCareModal();
        }else{
          onSubmitPageRedirect();
        }
      }
    }).catch((res)=> {
      oncancel();
    })
  }

  const update = (editChild) => {
    editChild.updated_by = currentUser.id;
    //editChild.member_role_id = currentUser.current_member?.role_record_id;
    ChildService.update(editChild).then((res) => {
      if (res.status === 200) {
        scope.child = res.data.child;
        onSubmitPageRedirect();
      }
    }).catch((res)=> {})
  }

  const onSubmitPageRedirect = () => {
    journeyDispatch({'type': "CLEAR"})
    if(props.isPopupMode){
      scope.child ? props.onFormSubmit(scope.child) : props.toggleModal();
    }else{
      props.history.push(
        `/health/common/page/${params.journey_profile_id}?type=infant_journey&child_id=${scope.child.id}`
      );  
    }
  }

  const closeNutritionCare = (e) =>{
    onSubmitPageRedirect();
    toggleNutritionCareModal(e);
  }

  const oncancel = () =>{
    onSubmitPageRedirect();
  }

  const checkMultiplePregnancy = (field) =>{
    if(field.client_id === 'multiple_pregnancy' && queryParam.associated_journey_id){
      return true;
    }
  }

  if (isFormRendered) {
    return (
      <>
        <FormContainer post={post} isPopupMode={props.isPopupMode}>
          <DynamicForm formMode={scope.formMode} 
            formId={(queryParam.form_id || FormTemplateId.HealthChildFormBasic)}
            form={scope.child} data={scope.data} formFn={scope.formFn} onCreate={create}
            onUpdate={update} post={post} onCancel={oncancel}
            setPost={setPost} isPopupMode={props.isPopupMode}
            fieldShowHideFn={checkMultiplePregnancy}/>
          <GeoLocation geoData={scope.child}/>
        </FormContainer>

        {isNutritionCareOpen && 
          <GenericModal component={NutritionCareNotification} 
            toggleModal={closeNutritionCare} 
            isOpen={isNutritionCareOpen}
            itemType="infant.activity" 
            itemUuid={scope.formFn.activity?.id}
            journeyProfileId={activeJourney?.id}
            mobileNo={activeJourney?.data?.cell_number}
            countryCode={activeJourney?.data?.country_code}/>
        } 
      </>
    )
  }

  return null;
  
}

export default ChildForm;
